import React, { useState, useEffect, useRef, useCallback } from 'react';
import { appHandler } from '@blaze-cms/core-ui';
import { useRouter } from 'next/router';
import { checkIfLoggedIn, getAccessToken, decodeAccessToken } from '@blaze-cms/core-auth-ui';
import { RouteContextProvider } from './RouteContext';

const RouteContextWrapper = ({ children }) => {
  const app = appHandler.getApp();
  const router = useRouter();
  const previousUrl = useRef('');
  const markedUrl = useRef('');
  const nextRedirect = useRef('');
  const [contextState, setContextState] = useState({ previousUrl: '' });

  const handleLoginAccountRedirects = useCallback(
    url => {
      // Redirect to my-account if logged in and trying to access login page
      const { isLoggedIn } = checkIfLoggedIn();
      let isGuestUser = false;
      if (isLoggedIn) {
        const token = getAccessToken();
        if (!token) return null;
        const {
          data: { user: { isGuest } = {} }
        } = decodeAccessToken(token);
        isGuestUser = isGuest;
      }
      if (isLoggedIn && !isGuestUser && url === '/login') {
        router.push('/Resolver', '/my-account');
        return true;
      }
      if ((!isLoggedIn || isGuestUser) && url === '/my-account') {
        router.push('/Resolver', '/login');
        return true;
      }

      return false;
    },
    [router]
  );

  useEffect(
    () => {
      handleLoginAccountRedirects(router.asPath);

      const storePreviousUrl = url => {
        previousUrl.current = router.asPath;
        setContextState({ previousUrl: router.asPath });
      };

      const handleUrlChange = urlToCheck => {
        if (urlToCheck === '/login') {
          markedUrl.current = previousUrl.current;
        } else if (urlToCheck === '/register' && !markedUrl.current) {
          markedUrl.current = previousUrl.current;
        } else if (urlToCheck === '/checkout') {
          markedUrl.current = '';
        }

        if (handleLoginAccountRedirects(urlToCheck)) return;

        if (nextRedirect.current) {
          router.push('/Resolver', nextRedirect.current);
          nextRedirect.current = '';
        }
      };

      const handleLogin = () => {
        if (markedUrl.current && markedUrl.current !== router.asPath) {
          // do redirect in routeChangeComplete event
          nextRedirect.current = markedUrl.current;
        }
      };

      app.events.on('package:core-auth-ui:login', handleLogin);
      router.events.on('routeChangeStart', storePreviousUrl);
      router.events.on('routeChangeComplete', handleUrlChange);

      return () => {
        app.events.off('package:core-auth-ui:login', handleLogin);
        router.events.off('routeChangeStart', storePreviousUrl);
        router.events.off('routeChangeComplete', handleUrlChange);
      };
    },
    [app.events, handleLoginAccountRedirects, router]
  );

  const contextValue = { ...contextState, previousUrlRef: previousUrl };

  return <RouteContextProvider value={contextValue}>{children}</RouteContextProvider>;
};

export default RouteContextWrapper;
