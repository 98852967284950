export const PLUGIN_NAME = 'shopware-fe-kp';

export const PROVIDER_KEY = 'blaze_shopware';
export const REGISTER_EMAIL_KEY = 'blaze_register_email';
export const PROVIDER_NAME = 'shopware auth';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const LOGIN_FORM = 'loginForm';
export const COMPONENT_PREFIX = 'shopware_kp_';
export const COOKIE_NAME = 'sw-context-token';
