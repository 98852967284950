import dynamic from 'next/dynamic';
import Cookies from 'universal-cookie';
import { PLUGIN_NAME, COMPONENT_PREFIX, COOKIE_NAME } from './constants';
import getAuthProvider from './utils/get-auth-provider';
import * as variants from './variants';

export default async function load(app) {
  app.events.once('load:custom:page-builder:component', addComponent => {
    addComponent({
      key: `${COMPONENT_PREFIX}registrationform`,
      component: dynamic(() =>
        import(/* webpackChunkName: "blazePbKpRegistrationForm" */ './components/RegistrationForm')
      )
    });
  });

  app.events.on('package:core-auth-ui:logout', () => {
    const cookies = new Cookies();
    cookies.remove(COOKIE_NAME);
  });

  app.events.once('plugin:auth-fe:load:auth-providers', loadAuthProvider =>
    loadAuthProvider(getAuthProvider())
  );

  app.events.on('load:custom:page-builder:variant', addVariant => {
    Object.values(variants).forEach(variant => {
      const { key } = variant;
      addVariant(`${COMPONENT_PREFIX}${key}`, variant);
    });
  });

  return {
    name: PLUGIN_NAME
  };
}

load.pluginName = PLUGIN_NAME;
