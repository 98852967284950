import LoginComponent from '../components/LoginComponent';
import { PROVIDER_NAME, PROVIDER_KEY } from '../constants';

export default function getAuthProvider() {
  return {
    name: PROVIDER_NAME,
    key: PROVIDER_KEY,
    LoginComponent
  };
}
